import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Spacer from '../Spacer';
import Container from '../Container';
import Picture from '../Picture/Picture';
import { formatSlug, renderContent } from '../../utils';
import * as Styles from './styles';

const SalesPointComponent = ({
  title,
  richDescription,
  image,
  hideContent = false,
}) => {
  const RegionsQuery = useStaticQuery(graphql`
    query RegionsQuery {
      settings: allContentfulSalesPointSettings(
        filter: { node_locale: { eq: "nl-NL" } }
      ) {
        edges {
          node {
            ...SalesPointSettingsFragment
          }
        }
      }

      regions: allContentfulSalesPointRegion(
        filter: { node_locale: { eq: "nl-NL" } }
        sort: { fields: name, order: ASC }
      ) {
        edges {
          node {
            ...SalesPointRegionFragment
          }
        }
      }
    }
  `);

  const fallback = RegionsQuery.settings.edges[0].node;
  const regionList = RegionsQuery.regions.edges.map((region) => region.node);

  return (
    <Spacer>
      {!hideContent &&
      (title || fallback.title || richDescription || fallback.description) ? (
        <Container>
          <Styles.Main>
            {title || fallback.title ? <h3>{title || fallback.title}</h3> : ''}

            {richDescription && richDescription.raw ? (
              <div>{renderContent(richDescription)}</div>
            ) : (
              ''
            )}

            {!richDescription &&
            fallback.description &&
            fallback.description.raw ? (
              <div>{renderContent(fallback.description)}</div>
            ) : (
              ''
            )}
          </Styles.Main>
        </Container>
      ) : (
        ''
      )}

      <Styles.Container>
        <Styles.Image>
          {image && <Picture image={image} />}

          {!image && fallback.image ? <Picture image={fallback.image} /> : ''}
        </Styles.Image>

        <Styles.Content>
          <h5>Kies een regio</h5>
          <ul>
            {regionList.map(({ id, name }) => (
              <li key={id}>
                <a
                  href={`/${formatSlug(fallback.slug, true)}/${formatSlug(
                    name,
                    true,
                    true
                  )}`}
                >
                  {name}
                </a>
              </li>
            ))}
          </ul>
        </Styles.Content>
      </Styles.Container>
    </Spacer>
  );
};

export default SalesPointComponent;
