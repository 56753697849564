import React from 'react';
import Breadcrumb from '@quantore/gatsby-theme-quantore/src/components/Breadcrumb/Breadcrumb';
import SalesPointComponent from '@quantore/gatsby-theme-quantore/src/components/SalesPointComponent/SalesPointComponent';
import { renderContent } from '@quantore/gatsby-theme-quantore/src/utils';

import { PageContainer, ContentContainer, PageTitle, Content } from './styles';

const StoresRegionOverview = ({
  pageContext: { breadcrumb, title, description, image },
  location,
}) => (
  <>
    {breadcrumb && <Breadcrumb breadcrumb={breadcrumb} />}

    <ContentContainer size="s">
      {title && <PageTitle limit={true}>{title}</PageTitle>}

      {description && description.raw && (
        <Content margin="true">{renderContent(description)}</Content>
      )}
    </ContentContainer>

    <main>
      <PageContainer>
        <SalesPointComponent hideContent={true} {...(image ? { image } : {})} />
      </PageContainer>
    </main>
  </>
);

export default StoresRegionOverview;
