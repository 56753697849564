import styled from 'styled-components';
import BaseContainer from '../Container';
import Typography from '../../typography';

export const Container = styled(BaseContainer)`
  @media (min-width: ${(props) => props.theme.breakpoints.m}) {
    display: flex;
    flex-wrap: nowrap;
  }
`;

export const Main = styled.div`
  margin-bottom: 3rem;

  h3 {
    margin: 0 0 0.75rem;
  }

  p {
    ${Typography.bodyLarge};

    &:last-child {
      margin: 0;
    }
  }
`;

export const Image = styled.div`
  @media (min-width: ${(props) => props.theme.breakpoints.m}) {
    flex: 1 1;
    max-width: 50%;
    min-width: 50%;
    padding-right: 2rem;
    margin-bottom: 2rem;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.l}) {
    padding-right: 8.3333%;
  }

  .gatsby-image-wrapper {
    display: block;
    margin: 0 auto;
    max-width: 100%;

    > img {
      object-fit: contain !important;
    }
  }
`;

export const Content = styled.div`
  @media (min-width: ${(props) => props.theme.breakpoints.m}) {
    flex: 1 1;
    max-width: 50%;
    min-width: 50%;
    padding: 3rem 0;
  }

  h5 {
    margin: 0 0 1.5rem;
  }

  ul {
    margin: 0;
    padding: 0;
    list-style: none;

    li {
      margin-bottom: 0.4rem;
    }

    @media (min-width: ${(props) => props.theme.breakpoints.s}) {
      display: flex;
      flex-wrap: wrap;

      li {
        flex: 1 1;
        max-width: 50%;
        min-width: 50%;
        margin-bottom: 1.2rem;
      }
    }
  }

  a {
    display: block;
    padding-right: 1rem;
    font-size: 1.375rem;
    line-height: 2rem;
    color: ${({ theme }) => theme.colors.greyDarkest};

    &:hover,
    &:focus {
      color: ${({ theme }) => theme.colors.brand};
    }
  }
`;
